
function TopAlert(props) {  
    if (props.show) {
      return (
          <div className={"container alerts alert-"+props.variant+" p-3"} role="alert" onClose={() => props.clear()}>
            <strong>{props.message}</strong>
          </div>
      );
    }
    return <></>;
  }
  export default TopAlert;