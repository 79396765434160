import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const featurebox= () => (
  <section className='no-top no-bottom bg-black'>
    <div className="container">
      <div className='row pt-4' >
              <div className="col-lg-4 col-md-6 mb-3">
                  <div className="feature-box f-boxed style-3">
                    <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                      <i className="bg-color-2 i-boxed icon_wallet"></i>
                    </Reveal>
                      <div className="text">
                        <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={1600} triggerOnce>
                          <h4 className="">Connect your Metamask</h4>
                        </Reveal>
                        <Reveal className='onStep' keyframes={fadeInUp} delay={1200} duration={600} triggerOnce>
                          <p className="">We will only require it to obtain the address with which you will participate in the drawings. 
                          </p>
                        </Reveal>
                      </div>
                      <i className="wm icon_wallet"></i>
                  </div>
              </div>

            <div className="col-lg-4 col-md-6 mb-3">
                <div className="feature-box f-boxed style-3">
                  <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                    <i className=" bg-color-2 i-boxed icon_key_alt"></i>
                  </Reveal>
                    <div className="text">
                      <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                        <h4 className="">Get your lucky number</h4>
                      </Reveal>
                      <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                        <p className="">We generate your lucky number, with which you will participate in all the draws until 2086.</p>
                      </Reveal>
                    </div>
                    <i className="wm icon_key_alt"></i>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-3">
                <div className="feature-box f-boxed style-3">
                  <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
                    <i className=" bg-color-2 i-boxed social_share"></i>
                  </Reveal>
                    <div className="text">
                      <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
                        <h4 className="">Increase your chances</h4>
                      </Reveal>
                      <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
                        <p className="">Please help us to promote <strong>Asteroid Belt Club</strong> with your friends and colleagues and get more lucky numbers.</p>
                      </Reveal>
                    </div>
                    <i className="wm social_share"></i>
                </div>
            </div>
          </div>
        </div>
      </section>
);
export default featurebox;