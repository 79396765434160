import {faqs} from "../faqs";



function Faq() {

  return (
  <div>
      <section className="jumbotron breadcumb  no-bg l-vh  no-bottom" style={{backgroundImage: `url(${'./img/bg_faq.jpg'})`, backgroundRepeat: 'no-repeat'}}>
        <div className="container" >
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="spacer-single"></div>
              <div className="text-center">
                <h1>Frequently Asked Questions</h1>
               </div> 
                <div className="col-md-12 rounded pt-2 bg-black bg-opacity-50">
                  <ul className="m-4 p-4 text-white">
                  {faqs.map(ask => (
                        <li key={ask.nu}><p><strong>{ask.as}</strong><br/>{ask.an}<br/></p></li>
                    ))}                    
                  </ul>
                </div>
              
                
          </div>
        </div>
      </div>
      </section>
  </div>
);
  }
export default Faq;