import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
import  {Link}  from 'react-router-dom';



setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);


const NavLink = props => (
  <Link 
    {...props}
  />
);



const Header= function(props) {

    const [openMenu, setOpenMenu] = React.useState(false);
    const [walletItem, setWalletItem]=useState("Connect Wallet");

    const closeMenu = () => {
      setOpenMenu(false);
    };
   
    useEffect(() => {
      if(props.status===1) {
        setWalletItem("Wallet Connected");
      }
      else if(props.status===4) {
        setWalletItem("Connecting...");
      }
      else{
        setWalletItem("Connect Wallet");
      }

    },[props.status]);


    const [showmenu, btn_icon] = useState(false);
    useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
        btn_icon(false);
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky");
          totop.classList.add("show");
          
        } else {
          header.classList.remove("sticky");
          totop.classList.remove("show");
        } if (window.pageYOffset > sticky) {
          closeMenu();
        }
      });
      return () => {
        window.removeEventListener("scroll", scrollCallBack);
      };
    }, []);
    return (
    <header id="myHeader" className='navbar white'>
     <div className='container'>
       <div className='row w-100-nav'>
          <div className='logo px-0'>
              <div className='navbar-title navbar-item'>
                <NavLink to="/">
                <img
                    src="./img/logo.png"
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="./img/logo-2.png"
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="./img/logo-light.png"
                    className="img-fluid d-none"
                    alt="#"
                  />
                </NavLink>
              </div>
          </div>

                    
              <BreakpointProvider>
                <Breakpoint l down>
                {showmenu &&
                <div className='menu'>
                    <div className='navbar-item'>
                      <NavLink to="/faq" onClick={() => btn_icon(!showmenu)}>
                        Frequently Asked Questions
                        <span className='lines'></span>
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/calendar" onClick={() => btn_icon(!showmenu)}>
                        Belter's Day Calendar
                        <span className='lines'></span>
                      </NavLink>
                    </div>  
                    <div className='navbar-item'>
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                      About ABC
                      <span className='lines'></span>
                      </NavLink>
                    </div>
                  </div>
                }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>
                    <div className='navbar-item'>
                      <NavLink to="/faq" onClick={() => btn_icon(!showmenu)}>
                      Frequently Asked Questions
                        <span className='lines'></span>
                      </NavLink>
                    </div>
                    <div className='navbar-item'>
                      <NavLink to="/calendar" onClick={() => btn_icon(!showmenu)}>
                      Belter's Day Calendar
                        <span className='lines'></span>
                      </NavLink>
                    </div>  
                    <div className='navbar-item'>
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                      About ABC
                      <span className='lines'></span>
                      </NavLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>

              <div className='mainside  xs-hide'>
                <button className="btn-main" onClick={() =>props.connect()}>{walletItem}</button>
              </div>
                  
      </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>

      </div>     
    </header>
    );
}
export default Header;