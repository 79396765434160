import WebApi from '../components/WebApi';

import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { useEffect } from 'react';




function Start(props) {
  
  const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
  useEffect(() => {
    if(props.status !== 1) {
      props.connect();
    }
  },[]);



  return (
    <section className="jumbotron breadcumb no-bg h-vh no-bottom" style={{backgroundImage: `url(${'./img/bg-shape-1.jpg'})`}}>
      
      <div className="container" >      
        <div className="row align-items-center">
          <div className="col-md-6">
              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
              <h2 className=""><span className="color">The Belter's Day</span></h2>
              <div className="spacer-10"></div>
              </Reveal>
              { props.status !== 1 ? (
                <>
                
                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                  <h1 className="thin">Be part of the expanse and take the humanity beyond Earth</h1>
                </Reveal>                
                <div className="spacer-10"></div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
                  <button className="btn-main lead" onClick={()=>props.connect()}>Connect Your wallet to Continue</button>                
                </Reveal>
                <div className="mb-sm-30"></div>
                </>
              ) : (
                <WebApi account={props.account} status={props.status} setAlert={props.alert}/>
              )}
              </div>
              <div className="col-md-6 xs-hide">
                <Reveal className='onStep' keyframes={fadeInUp} delay={900} duration={1500} triggerOnce>
                  <img src="./img/misc/nft.png" className="lazy img-fluid" alt=""/>
                </Reveal>
              </div>
            </div>
          </div>   
    </section>  
  )

}
export default Start;