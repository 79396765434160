import axios from "axios";
import { useEffect, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";


function WebApi(props) {
    const[status, setStatus]=useState(0);
    const [chances, setChances]=useState([]);
    const [refCode, setRefCode]=useState();
    const [refLink, setRefLink]=useState();

    const fadeInUp = keyframes`
        0% {
            opacity: 0;
            -webkit-transform: translateY(40px);
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
    `;

    function getBeltersData(account) {
        axios.get("https://api.asteroidbeltclub.com/belters/check?wallet="+account)
        .then(function (response) {
            setStatus(response.status);
            setChances(response.data.data.chances);
            setRefCode(response.data.data.referercode);
            setRefLink("https://www.beltersday.com/"+response.data.data.referercode);
        })
        .catch(function(error) {
            setStatus(error.response.status)
        });

    };

    function registerAccount() {
        const email=document.getElementById('email').value;
        const referer=document.getElementById('referer').value;
        axios.post("https://api.asteroidbeltclub.com/belters/register", {
            wallet: props.account,
            email: email,
            referer: referer,
        })
        .then(function(response) {
            props.setAlert('Thanks for joining Belter\'s Day', 'success');
            setStatus(response.status);
            setChances(response.data.data.chances);      
            setRefCode(response.data.data.referercode);
        })
        .catch(function(error) {
            props.setAlert(error.response.data.message, 'danger');
        });
    }

    useEffect(() => {
        getBeltersData(props.account);
        
    },[props.status, props.account]);

    useEffect(() => {
        setRefCode(localStorage.getItem("referer"));
    },[])

    return (
        <>
        {status===404 && (
            <div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                    <h1>Register to participate</h1>
                    <p ><strong>Use real email</strong>. In case you win, you will receive a code in the registered email, and you will not be able to claim your free asteroid without it. <strong>If a friend has referred you</strong>, use their referral code and increase your chances of both of you.</p>
                    <div className="spacer-10"></div>
                    <form className="form-border">
                        <div className="col-md-6">
                            <div className="field-set">
                                <label>Referred by</label>
                                <input type="text" name="referer" id="referer" className="form-control" value={refCode}/>
                            </div>
                        </div>                        
                        <div className="col-md-12">
                            <div className="field-set">
                                <label>Your Address</label>
                                <input type="text" name="address" id="address" className="form-control" value={props.account} disabled/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="field-set">
                                <label>Email</label>
                                <input type="text" name="emal" id="email" className="form-control" placeholder="email@example.com"/>
                            </div>
                        </div>
                        <div className="col-md-12 pull-right">
                            <button type="button" className="btn-main lead" onClick={()=>{registerAccount()}}>Register</button>
                        </div>
                    </form>
                </Reveal>
            </div>

        )} 
        {status===200 && (
            <div>
                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
                    <h2 style={{color:'#fff'}}>You're already participating</h2>
                    <div className="spacer-10"></div>
                    <div className=" col-md-10 shadow rounded p-4 pt-4 pb-4">
                        {chances.length===1 && (
                            <h1 style={{color:'#fff'}}>Your lucky number:</h1>
                        )}
                        {chances.length >1 && (
                            <h3  style={{color:'#fff'}}>Your {chances.length} lucky numbers:</h3>
                        )}                        
                    <ul>
                    {chances.map(chance => (
                        <li className="fs-2 text" key={chance.lucky}>{chance.lucky}</li>
                    ))}
                    </ul>
                    </div>
                    <div className="spacer-20"></div>
                    <h4 style={{color:'#fff'}}>Increase your chances!</h4>
                    <p>Share your referral code <strong>{refCode}</strong> or link to <a href={refLink}>https://www.beltersday.com/{refCode}</a> and add a new lucky number for each new referred.</p>
                </Reveal>
            </div>
        )}
        </>
    )

}
export default WebApi;