function About() {
  return (
    <section className="jumbotron breadcumb no-bg l-vh no-bottom" style={{backgroundImage: `url(${'./img/bg_about.jpg'})`, backgroundRepeat: 'no-repeat'}}>
      <div className="container" >
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="spacer-single"></div>
            <div className="text-center">
              <h1>Asteroid Belt Club</h1>
            </div>                
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 rounded pt-4 pb-4">
            <p className="p-2"><strong>The Asteroid Belt Club</strong> is a long-term initiative focused on providing a solution to the problem of asteroid ownership, 
            essential for the rational and peaceful exploitation of their mineral resources.</p>
            <p className="p-2">Today, humanity is taking the first steps towards becoming a multi-planetary species. 
            The race for space conquest has gained new momentum with the leadership of the private sector. 
            A permanent presence on the Moon and the colonization of Mars are on the way and will become a reality in the coming decades.</p>
            <p className="p-2">New technologies are lowering costs and simplifying processes and are making or will make previously unimaginable 
            industries possible. One of these is mining in the Asteroid Belt. According to NASA, including gold, iron, nickel, water and other 
            valuable resources that have been found there - primarily rare minerals widely used in electronics - the total mineral wealth of the
             Asteroid Belt can be estimated at $700 quintillion (7 followed by 20 zeros). 
             This is millions of times more than the total wealth of the Earth.</p>
             <p className="p-2">The concentration of minerals in the asteroids is far greater than on Earth, 
             their extraction potentially very profitable, of great positive impact for the ecology of our planet and essential for space colonization.</p> 
             <p className="p-2">To make asteroid mining possible, enormous technical and economic challenges will have to be overcome. 
             Only the appearance of a new class of entrepreneurs capable of summoning our best minds and raising venture capital will make it possible.  
             Resources that are hardly available without the framework of predictability provided by property rights, which are forbidden to states under 
             Article II of the "Treaty on Principles Governing the Activities of States in the Exploration and Use of Outer Space, 
             including the Moon and Other Celestial Objects".</p>
             <p className="p-2">For that reason we have resolved to tokenize each of the asteroids uniquely identified by the International Astronomical 
             Union and created a mechanism for their claim and transfer in the form of a non-fungible token (ERC721) within the ETHEREUM blockchain.</p>
             <p className="p-2">Each token expresses the claim of ownership over the asteroid it represents and its possession enables participation in 
             an autonomous governance community, whose rules ensure that it is capable of accumulating resources, financing ventures and initiatives and 
             promoting its legitimacy as a property registry.</p>


          </div>
        </div>          
      </div>
    </section>
);
  }
export default About;