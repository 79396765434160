import React from 'react';
import Reveal from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Link } from 'react-router-dom';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slidermain= () => (
 <div className="container" >
    <div className="row align-items-center">
          <div className="col-md-6">
              <div className="spacer-single"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
              <h2 className=""><span className="color">The Belter's Day</span></h2>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
              <h1 className="thin">Be part of the expanse and take humanity beyond Earth</h1>
              </Reveal>
              <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
              <p className=" lead">
              Get your chance to win the right to claim for free the ownership of an asteroid and become part of the <strong><a href="https://asteroidbeltclub.com">Asteroid Belt Club</a></strong> community.
              </p>
              <p className="lead">
              We raffle 10,000 tickets in decreasing numbers every July 7th through 2086. Each ticket will allow the winner to settle a property claim for free.
              </p>
              </Reveal>
              <div className="spacer-10"></div>
              <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={900} triggerOnce>
              <Link className="btn-main lead fs-2" to="/start">Start</Link>
              <div className="mb-sm-30"></div>
              </Reveal>
          </div>
          <div className="col-md-6 xs-hide">            
              <img src="./img/misc/nft.png" className="lazy img-fluid" alt=""/>
          </div>
      </div>
    </div>
);
export default slidermain;