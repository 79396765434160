//import Header from './menu/header';
import Home from './pages/home';
import Faq from './pages/faq';
import Calendar from './pages/calendar';
import About from './pages/about';
import Start from './pages/start';
import { useEffect, useState, useMemo } from 'react';
import Header from './menu/header';
import FeatureBox from './components/FeatureBox';
import Footer from './components/footer'
import TopAlert from "./components/TopAlert";


import ScrollToTopBtn from './menu/ScrollToTop';
import { Routes, Route, useLocation } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';

import Web3 from 'web3';
import { CONTRACT_CHAIN } from './config';


const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;


export const ScrollTop = ({ children, location }) => {
  useEffect(() => window.scrollTo(0,0), [location])
  return children
}


function App() {

  const [connected,setConnected]=useState(2);
  const [account, setAccount] = useState(); // state variable to set account.
  const [alert, setAlert]=useState({ 'show': false, title: 'test', 'message': "", 'variant': "danger" });

  const web3 = new Web3(Web3.givenProvider);

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query=useQuery();
  if(query.get("r")) localStorage.setItem('referer',query.get("r"));

  if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('chainChanged', (chainId) => {activateWallet();});
    window.ethereum.on('accountsChanged', (accounts) => {activateWallet();});
  }

  function launchAlert(message, variant) {
    setAlert(alert => ({ 'show': true, 'message': message, 'variant': variant }));
    setTimeout( () => clearAlert(), 8000);
  }

  function clearAlert() {
    setAlert(alert => ({ 'show': false,  'message': "", 'variant': "" }));
  }

  async function activateWallet() {
    try {
      const accounts = await web3.eth.requestAccounts();
      const chainId = await web3.eth.getChainId();
      setAccount(accounts[0]);
      if(chainId === CONTRACT_CHAIN) {
        setConnected(1);        
      }
      else {
        launchAlert('Please connect to Ethereum Blockchain','info');
        setConnected(5);
      }
    }
    catch(error) {
      switch(error.code) {
        case -32002:
          setConnected(4);
          break;
        case 4001:
        default:
          setConnected(0);
      }
      launchAlert(error.message,'danger');
      console.log(error.code);
      console.log(error.message);
    }
  }
  useEffect(() => {
    async function load() {
      if (typeof window.ethereum === 'undefined') setConnected(3);
      
    };
    load();
  }, []);
  const conectar = () => {
    setConnected(4);
    activateWallet();
  };

  
  return (
    <>
     <div className="wrapper b-vh bg-black" >
      <GlobalStyles />
      
      <Header status={connected} connect={conectar} />
      <TopAlert show={alert.show} message={alert.message} variant={alert.variant} clear={clearAlert} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:referer" element={<Home />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/about" element={<About />} />
        <Route path="/start" element={<Start status={connected} connect={conectar} account={account} alert={launchAlert}/>} />
      </Routes>
      <FeatureBox />
      <ScrollToTopBtn />      
      <Footer />
    </div>
    </>
  );
}

export default App;
