function Calendar() {
  return (
    <section className="jumbotron breadcumb  no-bg l-vh  no-bottom" style={{backgroundImage: `url(${'./img/bg_calendar.jpg'})`, backgroundRepeat: 'no-repeat'}}>   
      <div className="container" >
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="spacer-single"></div>
            <div className="text-center">
              <h1>Belter's Day Calendar</h1>
              <p>Every July 7th until the year 2086 we will raffle a total of 10,000 tickets that will allow <br/>
              you to claim for free the ownership of an asteroid and participate in the community of members of the <strong>Asteroid Belt Club</strong></p>
            </div>                
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 rounded">
            <table width="100%" className="table table-striped table-dark">
              <thead>
                <tr className="text-center">
                  <th>Year</th>
                  <th>Rounds</th>
                  <th>Tickets per round</th>
                  <th>Blocks lapse</th>
                  <th>Total Tickets</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>2023</td>
                  <td>72</td>
                  <td>60</td>
                  <td>80</td>
                  <td>4320</td>
                </tr>
                <tr className="text-center">
                  <td>2024</td>
                  <td>72</td>
                  <td>30</td>
                  <td>80</td>
                  <td>2160</td>
                </tr>     
                <tr className="text-center">
                  <td>2025</td>
                  <td>72</td>
                  <td>15</td>
                  <td>80</td>
                  <td>1080</td>
                </tr>    
                <tr className="text-center">
                  <td>2026</td>
                  <td>72</td>
                  <td>8</td>
                  <td>80</td>
                  <td>576</td>
                </tr>   
                <tr className="text-center">
                  <td>2027</td>
                  <td>72</td>
                  <td>6</td>
                  <td>80</td>
                  <td>432</td>
                </tr>   
                <tr className="text-center">
                  <td>2028</td>
                  <td>72</td>
                  <td>4</td>
                  <td>80</td>
                  <td>288</td>
                </tr>   
                <tr className="text-center">
                  <td>2029</td>
                  <td>48</td>
                  <td>4</td>
                  <td>120</td>
                  <td>192</td>
                </tr> 
                <tr className="text-center">
                  <td>2030</td>
                  <td>48</td>
                  <td>3</td>
                  <td>120</td>
                  <td>144</td>
                </tr> 
                <tr className="text-center">
                  <td>2031</td>
                  <td>48</td>
                  <td>2</td>
                  <td>120</td>
                  <td>96</td>
                </tr> 
                <tr className="text-center">
                  <td>2032</td>
                  <td>48</td>
                  <td>1</td>
                  <td>120</td>
                  <td>48</td>
                </tr> 
                <tr className="text-center">
                  <td>2033-2042</td>
                  <td>24</td>
                  <td>1</td>
                  <td>240</td>
                  <td>24</td>
                </tr> 
                <tr className="text-center">
                  <td>2043-2052</td>
                  <td>16</td>
                  <td>1</td>
                  <td>360</td>
                  <td>16</td>
                </tr>   
                <tr className="text-center">
                  <td>2053-2062</td>
                  <td>12</td>
                  <td>1</td>
                  <td>480</td>
                  <td>12</td>
                </tr>                                      
                <tr className="text-center">
                  <td>2063-2086</td>
                  <td>6</td>
                  <td>1</td>
                  <td>960</td>
                  <td>6</td>
                </tr>                                                        
              </tbody>
            </table>
          </div>
        </div>          
      </div>
    </section>
);
  }
export default Calendar;