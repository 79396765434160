export const faqs=[
    {"nu":2,"as":"What exactly do I win if I get drawn?","an":"You win a lottery ticket that you can use to claim a random asteroid."},
    {"nu":2,"as":"What is the value of this ticket?","an":"The cost of making a Random claim is 0.05 ETH."},
    {"nu":2,"as":"Can I sell this ticket?","an":"No. The ticket is linked to your wallet and is non-transferable."},
    {"nu":2,"as":"Will I be able to sell the asteroid I get?","an":"Of course, you can! After minting, you will have full ownership of the asteroid."},
    {"nu":2,"as":"How many tickets will you draw this year?","an":"You can check it in our Calendar: https://www.beltersday.com/calendar"},
    {"nu":2,"as":"Why do you ask for my email for registration?","an":"If you win a ticket, we need an easy way to notify you and send you the code to claim it. Don't worry about SPAM. We don't like it either!"},
    {"nu":2,"as":"Can I register on the day of the drawing?","an":"Registrations close one hour before the start of Belters Day (July 6 at 23.00 GMT). New registrations after that time will participate in the following year's drawings."},
    {"nu":2,"as":"Do I automatically participate every year when I register?","an":"No, registrations are only valid for the next Belters Day."},
    {"nu":2,"as":"Why do I have to register every year?","an":"Can you imagine how many registrations could accumulate over 50 years? We want the winners to be active people who can exercise their rights to claim!"},
    {"nu":2,"as":"Do I still participate for the rest of the day if I win?","an":"Yes, if one of your lucky numbers wins, all the other numbers you have will continue to participate! There is no limit to how many asteroids you can win!"},
    {"nu":2,"as":"In my wallet, I have several Asteroids that I bought. Can I still participate?","an":"Of course, you can!"},
    {"nu":2,"as":"In previous years, I have already won a raffle. Can I participate this year?","an":"Of course, you can!"},
    {"nu":2,"as":"Can I participate in the referral program?","an":"When you register with your wallet, we will automatically generate your referral code!"},
    {"nu":2,"as":"Can I know how many referrals I have?","an":"When you connect your wallet to our website, you will see all the tickets you have accumulated and the total count."},
    {"nu":2,"as":"I forgot my referral code. How can I find it?","an":"Just connect your wallet, and you will see it right away."},
    {"nu":2,"as":"How do you calculate my lucky number?","an":"Your lucky number is the CRC32 of the string representing the hexadecimal value of the Wallet address you registered. If you used a referral code, your extra lucky number comes from the CRC32 of the concatenation of the strings representing the hex value of the Wallet address with the hex value of the Wallet address of the referral code holder."},
    {"nu":2,"as":"How do you calculate the remaining lucky numbers I earn when using my referral code?","an":"You will generate one extra number for each referral that uses your code. That is calculated from the CRC32 of the concatenation of the strings representing the hexadecimal value of your Wallet address with the hexadecimal value of your referral's Wallet address."},
    {"nu":2,"as":"How do I know in which blocks the draws will take place?","an":"The first draw of the day will be on the first block discovered on the Ethereum network during Belters Day (GMT). From there, we will calculate the exact block of each draw based on our Calendar ( https://www.beltersday.com/calendar )"},
    {"nu":2,"as":"How do you get the lucky number from a block?","an":"You get it by calculating the unsigned CRC32 value of the concatenation of strings representing the hexadecimal value of the block's hash, the hexadecimal value of the wallet address of the miner who discovered it, and its timestamp."},
    {"nu":2,"as":"Does my exact number have to come up to win?","an":"No. Everyone with the exact number or higher will receive a ticket until the number of tickets for that raffle round reaches. In the event of a tie, the person who received their number first will receive a raffle ticket."},
    {"nu":2,"as":"You have drawn my number!!!! What do I need to do now?","an":"Congratulations!!! In the next few hours, you will receive an email with instructions on how to mint your ticket."},
    {"nu":2,"as":"How long do I have to claim it if I win a ticket?","an":"You will have 1728000 blocks starting from the block where in which your ticket was drawn. In days, almost 300! If you do not do so in time, your code will expire, and the coupon will be added to the first draw of the following year."},
    {"nu":2,"as":"I have the email with the code. Do I need anything else?","an":"Remember, you can only use the code from the associated wallet. You will also need to have enough ETH in your wallet to cover the GAS cost of the transaction."},
    {"nu":2,"as":"Does winning a raffle entitle me to join the club?","an":"You must have at least one asteroid in your wallet to join the club. Asteroids drawn on Belters Day give you the same rights as if you had minted them. So you have access to the club and can vote as long as you have the asteroid in your wallet."},
    {"nu":2,"as":"Can I trust the results?","an":"Of course, you can! The whole process is transparent. Also, at the moment of closing the registrations for the current year, we will upload the complete list of participating lucky numbers to IPFS, where you can download and check each of the results of all the draws of the day."},
];
